<template>
    <div class="CreateLesson" >
        <div class="nav">
            <van-row>
                <van-col span="4">
                    <div @click="back()">
                        <van-icon name="arrow-left" />
                    </div>
                </van-col>
                <van-col span="16">
                    {{lang.createLesson}}
                </van-col>
                <van-col span="4">
                    <div @click="toggleLanguage">
                        <img class="lang-img" :src="lang.langBtn">
                    </div>
                </van-col>
            </van-row>
        </div>
        <div class="content">
            <img style="width:100%" :src="course.main_img || Config.defaultMainImg" />
            <van-row align="left" style="background:#fff;padding:10px 0 ;"> 
                <van-col offset="1" span="22" align="left">
                    <div class="title">{{course.en_name}}</div>
                </van-col>
                <van-col offset="1" span="22" align="left">
                    <div class="title">{{course.name}}</div>
                </van-col>
                <van-col offset="1"  span="22" align="left">
                    <div class="class-info">{{lang.time}}:{{course.kernel.start_time.substr(0,5)}} - {{course.kernel.end_time.substr(0,5)}}</div>
                </van-col>
                <van-col offset="1"  span="22" align="left">
                    <div class="class-info">{{lang.place}}: {{course.kernel.venue.detail}}</div>
                </van-col>
            </van-row>
            <van-cell @click="showDate=true" :title="lang.selectDate" :value="class_date" is-link />
        </div>
        <footer>
            <van-row align="center" style="height:100%;">
                <van-col offset="1" span="21">
                    <div class="form-button" @click="createLesson">{{lang.createLesson}}</div>
                </van-col>
            </van-row>
        </footer>
        <van-calendar 
            :title="lang.selectDate"
            v-model:show="showDate" 
            :min-date="new Date(2021, 0, 1)"
            @confirm="selectDate"
            first-day-of-week="1"
            :confirm-text="lang.determine"
        />
    </div>
     
</template>

<script>
import { reactive, toRefs } from 'vue';
import Common from "../../library/Common";
import Config from "../../library/Config";
import { useRouter } from 'vue-router';
import request from '../../library/MyRequest';
import "@/css/form.css";
import { Toast } from 'vant';

export default {
    name: 'CreateLesson',
    setup(){
        const common = Common;
		const router = useRouter();
		common.chechLogin(router);
		
        const course = JSON.parse(router.currentRoute.value.params.course);
        
        let state = reactive({
            lang:{
                langBtn:"",
                createLesson:"",
                time:"",
                place:"",
                selectDate:"",
                determine:"",
                lessonCreated:"",
                createdLessonSuccess:"",
                date_is_null:"",
                
            },
            showDate:false,
            class_date:null,
        });

        common.initLanguage(state.lang);
        state.lang.langBtn = common.getLanguageBtn();
        const toggleLanguage = function(){
            common.toggleLanguage();
            common.initLanguage(state.lang);
            state.lang.langBtn = common.getLanguageBtn();
        }

        const back = function(){
             router.push({name: 'CourseList'});
        }

        const createLesson = async()=>{
			if(state.class_date == null){
				Toast(state.lang.date_is_null);
				return ;
			}
            let sendData ={
                course_id:course.id,
                class_date:state.class_date,
            }
            try{
                var create = await request.post(`/lesson/`,sendData);
                if(create.status == 201){
                    Toast.success({
                        forbidClick:true,
                        duration:3000,
                        message:state.lang.createdLessonSuccess,
                        onClose:()=>{
                            router.push({
                                name:'rollCall',
                                params:{
                                    id:create.data.id,
                                }
                            });
                        }
                    })
                }
            }catch(error){
                if(error.response.status == 403){
                    Toast(state.lang.lessonCreated);
                }
            }
            
        }

        const selectDate = function(date){
            state.showDate = false;
            state.class_date = date.format("yyyy-MM-dd");
        }
        return {
            ...toRefs(state),
            common,
            toggleLanguage,
            createLesson,
            back,
            Config,
            course,
            selectDate
        };
    }
}
</script>

<style scoped>

.CreateLesson{
   width: 100%;
   height:100%; 
   background:#F6F6F6;
}

.content{
    font-size: 28px; 
    width: 100%;
    height:calc(100% - 100px - 100px);
    overflow-y:auto;
    text-align: left;
}

footer{
    height: 100px;
    background-color: #fff;
    width: 100%;
}

.title{
    font-size: 32px; 
    margin-bottom:10px;
    font-weight: 600;
}
.class-info{
    font-size: 28px;
    margin-bottom:10px;
}
</style>